<script setup lang="ts">
import { cva } from 'class-variance-authority';

enum Sizes {
  ExtraSmall = 'xs',
  Small = 'sm',
  Medium = 'md'
}

enum Colors {
  White = 'white',
  Gray = 'gray',
  DarkGray = 'dark-gray'
}

interface Props {
  size?: Sizes;
  color?: Colors;
  className?: string;

  [x: string]: any;
}

const props = withDefaults(defineProps<Props>(), {
  size: Sizes.Medium,
  color: Colors.Gray
});

const tagClass = computed(() => {
  return cva(
    [
      'inline-flex justify-center items-center flex-nowrap px-3.5 text-nowrap font-medium rounded-full border',
      props.className
    ],
    {
      variants: {
        size: {
          [Sizes.ExtraSmall]: 'py-[3px] text-sm leading-[18px]',
          [Sizes.Small]: 'py-1 text-base leading-5',
          [Sizes.Medium]: 'py-1 text-lg leading-6'
        },
        color: {
          [Colors.White]: 'bg-white text-brand-navy-700 border-white',
          [Colors.Gray]:
            'bg-brand-white text-brand-navy-600 border-brand-light-gray',
          [Colors.DarkGray]:
            'bg-brand-gray text-brand-navy-600 border-brand-gray'
        }
      }
    }
  )({
    size: props.size,
    color: props.color
  });
});
</script>
<template>
  <div :class="tagClass">
    <slot />
  </div>
</template>
